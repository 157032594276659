
import { FormBlock } from "@/components/FormBuilder/types";
import { objectDifference } from "@/helpers";
import { accreditedBuilderDefault } from "@/helpers/accreditedBuilder";
import {
  accreditedBuilderMapActions,
  accreditedBuilderMapMutations,
  accreditedBuilderMapState
} from "@/store/modules/accreditedBuilder";
import * as types from "@/store/mutation-types";
import Vue from "vue";
import { accreditedBuilderForm } from "../../../forms/admin/accreditedBuilder";
export default Vue.extend({
  name: "createAccreditedBuilder",
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      disablePrimaryButton: true,
      loadingText: ""
    };
  },
  created() {
    this.SET_EDIT({} as any);
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...accreditedBuilderMapState(["editing", "makingApiRequest"]),
    updatedFields(): any {
      const edited = this.editing;
      const original = accreditedBuilderDefault({});
      return edited && original ? objectDifference(edited, original) : {};
    },
    accreditedBuilderFields(): FormBlock[] {
      return accreditedBuilderForm;
    }
  },
  methods: {
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    },
    async formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      this.editField({ key, value });
    },
    formFieldBlurHandler({ key, value }: { key: any; value: any }): void {
      const uppercasedLetters = value.toUpperCase();
      this.editField({ key, value: uppercasedLetters });
    },
    ...accreditedBuilderMapMutations(["SET_EDIT"]),
    ...accreditedBuilderMapMutations({ editField: types.SET_EDIT_FIELD }),
    ...accreditedBuilderMapActions(["createAccreditedBuilder"]),
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "saveAccreditedBuilder":
          await this.submit();
          break;
        case "cancelAccreditedBuilder":
          this.$router.push("/admin/accreditedBuilder").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        this.loadingText = "Creating company. Please wait...";
        this.isLoading = true;
        const accreditedBuilderKey = this.editing?.accreditedBuilderName
          .split(" ")
          .join("_");
        const payload = { ...this.editing, accreditedBuilderKey };
        await this.createAccreditedBuilder(payload);
        this.$appNotifySuccess("Accredited builder created");
        this.$router.replace("/admin/accreditedBuilder");
        this.SET_EDIT({} as any);
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  }
});
