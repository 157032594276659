import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField
} from "@/components/FormBuilder/Helpers";

export const accreditedBuilderForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "accreditedBuilderName",
          label: "Accredited Builder Name",
          placeholder: "Accredited Builder Name",
          required: true
        })
        // useTextField({
        //   key: "accreditedBuilderKey",
        //   label: "Accredited Builder Key",
        //   placeholder: "Accredited Builder Key",
        //   required: false
        // })
      ]
      // { layout: "2-col" }
    )
  ])
];
