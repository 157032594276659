var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"element-loading-text":_vm.loadingText,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    disabled: Object.keys(_vm.updatedFields).length === 0,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelEditAccreditedBuilder',
    disabled: _vm.isLoading
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.showActivities)?_c('div',[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Edit Accredited builder ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Modify the builder details below. ")])])]),(_vm.editing)?_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.accreditedBuilderFields,"formValues":_vm.editing,"dynamicOptions":{},"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":() => {}}}):_vm._e(),(_vm.editing)?_c('div',{staticClass:"flex justify-start",class:[
          Object.keys(_vm.updatedFields).length > 0 ? 'justify-between' : ''
        ]}):_vm._e()],1):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)}):_vm._e()]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the accredited builder you were looking for could not be found.","actionButtonTitle":"Back to accredited builder","actionRedirectPath":"/admin/accreditedBuilder"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }