
import Vue from "vue";
import * as types from "@/store/mutation-types";
import { FormBlock } from "@/components/FormBuilder/types";
import { objectDifference } from "@/helpers";
import {
  accreditedBuilderMapActions,
  accreditedBuilderMapGetters,
  accreditedBuilderMapMutations,
  accreditedBuilderMapState
} from "@/store/modules/accreditedBuilder";
import { accreditedBuilderForm } from "@/forms/admin/accreditedBuilder";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

export default Vue.extend({
  name: "SingleAccreditedBuilderView",
  mixins: [ActivitiesMixin],
  data() {
    return {
      resourceNotFound: false,
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      successMessage: "",
      loadingText: ""
    };
  },
  created() {
    this.getAccreditedBuilder(this.accreditedBuilderId)
      .then(() => {
        this.SET_EDIT(this.accreditedBuilder);
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  methods: {
    ...accreditedBuilderMapActions([
      "getAccreditedBuilder",
      "updateAccreditedBuilder"
    ]),
    ...accreditedBuilderMapMutations(["SET_EDIT"]),
    ...accreditedBuilderMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      if (key === "accreditedBuilderName") {
        this.editField({ key, value: value.trim().toUpperCase() });
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "cancelEditAccreditedBuilder":
          this.accreditedBuilderCallback();
          break;
        case "save":
          this.updateCallback();
          break;
      }
    },
    async accreditedBuilderCallback(): Promise<any> {
      this.$router.push("/admin/accreditedBuilder").catch(() => {});
    },
    async deleteOneAccreditedBuilder(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting accredited builder. Please wait...";
        this.$appNotifySuccess("Accredited Builder Successfully Deleted");
        this.accreditedBuilderCallback();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async updateCallback(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Updating accredited builder. Please wait...";
        const update = {
          accreditedBuilderName: this.editing?.accreditedBuilderName,
          accreditedBuilderKey: this.editing?.accreditedBuilderName
            .toUpperCase()
            .split(" ")
            .join("_")
        };
        await this.updateAccreditedBuilder({
          id: this.accreditedBuilderId,
          update
        });
        this.$appNotifySuccess("Accredited Builder Updated Successfully");
        this.accreditedBuilderCallback();
      } catch (err) {
        this.errorMessage = "Operation specified is not supported";
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  },
  computed: {
    ...accreditedBuilderMapGetters([
      "getAccreditedBuilderById",
      "editingAccreditedBuilder"
    ]),
    ...accreditedBuilderMapState(["makingApiRequest", "editing"]),
    accreditedBuilderId(): string {
      return this.$route.params.accreditedBuilderId;
    },
    accreditedBuilder(): any {
      return this.getAccreditedBuilderById(this.accreditedBuilderId);
    },
    updatedFields(): any {
      const edited = this.editingAccreditedBuilder;
      const original = this.accreditedBuilder;
      return edited && original ? objectDifference(edited, original) : {};
    },
    accreditedBuilderFields(): FormBlock[] {
      return accreditedBuilderForm;
    },
    queryOverride(): any {
      return {
        "data.accreditedBuilderId": this.accreditedBuilderId,
        activityType: "AccreditedBuilderActivity"
      };
    }
  }
});
